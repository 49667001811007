import styled from "styled-components";

export const MainHero = styled.main`
  // display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: -4rem;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    text-align: left;
  }
`;

export const MainDetails = styled.div`
  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 0rem;
  }
`;

export const MainTitlte = styled.h1`
  font-size: 2.125rem;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  letter-spacing: 0.25rem;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 2.5rem;
  }

  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.8rem;
  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    font-size: 1.5rem;
  }
`;

export const MainTitleLine = styled.span`
  position: relative;
  z-index: 5;

  &::before {
    content: "";
    position: absolute;
    bottom: 0.25rem;
    left: 0;
    right: 0;
    height: 0.6rem;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: -1;

    @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 0.4rem;
      bottom: 0.5rem;
    }

    @media all and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 0.25rem;
    }
  }
`;

export const MainTitleTextGradient = styled.span`
  background: -webkit-linear-gradient(#fcfaf9 40%, #fbddb1 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MainDescription = styled.p`
  margin: 1rem 0 2rem 0;
  color: ${({ theme }) => theme.colors.tertiary};
  line-height: 22px;
  // font-size: 0.875rem;
  text-align: center;
  letter-spacing: 0.9px;
  grid-area: left;
  align-items: center;
  margin: auto;
  // justify-content: center;
  
  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.5rem;
    width: 70%;
    line-height: 1.75rem;

  }
  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    
    p,li{
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
`;

export const MainButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 1rem;
  grid-area: rightbottom;
  // align-items: center;
  margin: auto;
  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: start;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const MainImage = styled.div`
  width: 100%;
  // background: -webkit-linear-gradient(#181818 0%, rgba(24, 24, 24, 0) 100%);
  // z-index: 50;
  // position: relative;
  margin: auto;

  align-items: center;
  justify-items: center;
  border-radius: 5px;
  grid-area: righttop;

  // @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
  //   background: transparent;
    height: 28rem;
  // }
`;

export const HeroImage = styled.img`
  height: 14rem;
  // background-color:orange;
  // top: -2rem;
  text-align: center;
  // align-items: center;
  // justify-items: center;
  // justify-content: center;
  // align-content: center;
  margin: auto;

  // left: 50%;
  // position: absolute;
  // transform: translateX(-50%);
  grid-area: righttop;
  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 18rem;

  }
`;

export const HeroContent = styled.div`
display: grid;
grid-template-areas: 
    "left righttop"
    "left rightbottom";
grid-template-columns: 6fr 4fr;
@media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  display: flex;
  flex-direction: column;
}
div a {
  font-size: 0.875rem;
}
`;