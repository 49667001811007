import React from "react";
import parser from 'html-react-parser';
import styled from "styled-components";



const Message = (props) => {
    // console.log(props.message)
    // console.log(props.table)

  return (
    <div>
    <MessageStyle>
                {parser(props.message)} 
    </MessageStyle>
    <TableStyle> 
            {(props.table)}
    </TableStyle>

</div>

  );
};

const MessageStyle = styled.div`
// padding: 0.85rem 1.9rem;
  line-height: 1.5;
  font-size: .85rem;
  // max-width: 100px;
  text-align: center;
  justify-content: center;

`;

const TableStyle = styled.table`
margin: auto;
padding: 0.85rem 1.9rem;
  line-height: 1.5;
  font-size: .55rem;
  // max-width: 100px;
  text-align: center;
  justify-content: center;
  color: #afafaf;
`;

export default Message;
