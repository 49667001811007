import React from "react";
import {
  HeroImage,
  HeroContent,
  // MainButtons,
  MainDescription,
  MainDetails,
  MainHero,
  MainTitlte,
} from "../styles/Hero.styled";
// import { StyledButton } from "../styles/Button.styled";
import {
  // BsArrowUpRightCircleFill as ArrowIcon,
  // BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";
import roselogo from "../../assets/roselogo_new2.png"

const Hero = (props) => {

  return (
    <MainHero>
      <MainDetails>
        <MainTitlte>
          <span style={{ color: "lightred", "opacity": ".4" }}>#</span>RoseGarden.Yoga
          {/* Make yourself <MainTitleLine>stronger </MainTitleLine> <br />
          <MainTitleTextGradient>than your excuses.</MainTitleTextGradient> */}
        </MainTitlte>

        <HeroContent>
          <div className="myhero">

          <HeroImage src={roselogo} alt="main image" />
          </div >
          {/* <RGButton link={props.aclink} label="New Class Schedule"/>
        <RGButton link={props.gclink} label="Old Class Schedule"/> */}

          <MainDescription>
            A consistent yoga practice may be the best investment in your long term health.
            We provide the foundations for transformation through honest Ashtanga Yoga principals. In 5 sessions, you will feel the difference. After 10 sessions, you will see the difference.
          </MainDescription>
        </HeroContent>
      </MainDetails>

    </MainHero>
  );
};

export default Hero;
