// import theFounder from "./assets/the-founder.png";
// import parse from "html-react-parser"

// import theProfessionals from "./assets/the-professionals.png";
// import michael from "./assets/michael_side2.jpg"
// import michaelnb from "./assets/michael_nobg.png"
import michaelbb from "./assets/rg03_fix.png"
// import yclass from "./assets/class.jpg"
import rosegarden from "./assets/rg05.png"
import classpix from "./assets/rg04.png"
// import android from "./assets/icons8-android-os-50.png"
// import ios from "./assets/icons8-ios-logo-50.png"

// import droppin from './assets/droppin.png'
// const rgurl="https://www.google.com/maps/place/Municipal+Rose+Garden/@37.3318851,-121.9308059,17z/data=!3m1!4b1!4m5!3m4!1s0x808fcb146d96926f:0xff35e99b970126d6!8m2!3d37.3318809!4d-121.9286119"
export const aboutData = [

  // {
  //   id: 2,
  //   img: yclass,
  //   // subTitle: "WHO WE ARE",
  //   // title: "Exprienced and Professonal",
  //   details:
  //     " By assimilating his scientific background with yoga physiology and meditation, he presents a new grounded perspective on the practice. The challenging motivated flow of Michael’s classes will leave you feeling strong and centered – his approach to teaching will develop awareness, flexibility, strength, and confidence.",
  // },
  {
    id: 3,
    img: michaelbb,
    // subTitle: "WHO WE ARE",
    title: `The Municipal Rose Garden`,
    imgStart: true,
    details: 
    `<p class="aboutPara">The San Jose Municipal Rose Garden is a tranquil and beautiful place to practice yoga. The fresh air, gentle scents, and open space provide the perfect setting for a relaxing and invigorating practice. </p>

    <ul>
     <li class="aboutPara"><b>Clean air</b>: The garden is filled with fresh air that is scented with the gentle fragrances of roses and pine trees.</li>
     <li class="aboutPara"><b>Open space</b>: The garden has a large, open space that is perfect for practicing yoga. There are no walls or other obstacles to get in your way.</li>
     <li class="aboutPara"><b>Community</b>: The garden is a great place to meet other people who are interested in yoga. You can also find classes and workshops that are led by experienced yoga instructors.</li>
    </ul>
    <p class="aboutPara">Parking is free and generally abundant around the park (unless there is a park event). However, if you live nearby, biking to the park is an invigorating and preferred alternative.</p>
    `   
  },
  {
    id: 9,
    img: rosegarden,
    // subTitle: "Yoga Instructor",
    title: "Yoga Instruction",
    details:
    `<p class="aboutPara">Michael Athanas, Ph.D., an E-RYT certified yoga instructor with specialization in Ashtanga, 
    has immersed himself in many styles of yoga since 2002 and more than a decade of yoga teaching experience.</p>

    <p class="aboutPara">With a Ph.D. in Physics, Michael 
    spent nearly two decades exploring the fundamental structure of matter at particle accelerator 
    laboratories around the world as well as data sciences in the molecular biology industries. He finds 
    yoga philosophy & asana to complement his stringent 
    <a href='https://vastscientific.com' target='_blank'>scientific profession</a> – both work in tandem 
    to increase energy, focus, and awareness. </p>

    <p class="aboutPara">By assimilating his scientific background with yoga physiology 
    and meditation, he presents a new grounded perspective on the practice. The challenging motivated flow 
    of Michael’s classes will leave you feeling strong and centered – his approach to teaching will develop awareness, 
    flexibility, strength, and confidence.</p>`,
    imgStart: false,
  },
  {
    id: 11,
    img: classpix,
    // subTitle: "Yoga Instructor",
    title: "Classes",
    details:
    `
    <p class="aboutPara">Our classes are suitable for all levels and are based upon core Ashtanga yoga principles.  
    Classes are carefully designed to help you to cultivate flexibility, develop core strength and endurance, 
    and improve balance and control stress.</p>
    
    <p class="aboutPara">In the case if inclimate 
    weather, we will offer virtual classes.  </p>
        
    <p class="aboutPara">
    Our park classes are <em>donation-based</em>.  </p>
    


`,
    imgStart: true,
  },
];
