import React from "react";
// import {
//   MapContent,
//   SectionTitle,
// } from "../styles/SectionHeaders.styled";

import {
    MapTitle,
    MapSection,
    MapContent
} from "../styles/Map.styled";
// import { Section } from "../styles/Sections.styled";


const Map = () => {
    var wwidth = window.window.innerWidth*.7;
    const maplink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1333.8528251543155!2d-121.92921726541796!3d37.33174631566881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcb146d96926f%3A0xff35e99b970126d6!2sMunicipal%20Rose%20Garden!5e0!3m2!1sen!2sus!4v1686706487521!5m2!1sen!2sus"
    const extmaplink = "https://www.google.com/maps/place/Municipal+Rose+Garden,+1649+Naglee+Ave,+San+Jose,+CA+95126/@37.3318809,-121.9286119,17z/data=!4m6!3m5!1s0x808fcb146d96926f:0xff35e99b970126d6!8m2!3d37.3318809!4d-121.9286119!16zL20vMDk5bHRx"
    return (
        <MapSection id="map">
            <a href={extmaplink} target="_blank" rel="noreferrer">
            <MapTitle>getting there</MapTitle>
            </a>
            <MapContent style={{"margin": "0 auto ","paddingLeft":"0px"}}>

                <iframe title="RoseGarden.Yoga"src={maplink} width={wwidth} height="500" ></iframe>

            </MapContent>
        </MapSection>
    )
}

export default Map;