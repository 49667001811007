import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: ${({ theme }) => theme.fonts.montserrat};
        background: ${({ theme }) => theme.colors.primaryBG};
        color: #fff;   
        overflow-x: hidden;
    }

    .container {
        max-width: 70%;
        width: 100%;
        margin: 0 auto;
    }
    @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        .container {
            max-width: 90%;
        }
        p,li{
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
      }

    li {
        list-style: none;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    // a:hover {
    //     background-color: black;
    //   }

    button {
        font-family: inherit;
    }
    
    section,
    footer {
        margin: 1.5rem 0;
        padding: 1.5rem 0;
    }
`;
