export const theme = {
  fonts: {
    montserrat: "'Montserrat', sans-serif",
    merriweather: "'Merriweather', serif",
    poppins: "'Poppins', sans-serif",
    courier: "'Courier New', Courier, monospace",
  },
  colors: {
    primary: "#FF0034",
    secondary: "#0736DB",
    tertiary: "#C2C2C2",
    primaryBG: "#111111",
    secondaryBG: "#181818",
  },
  breakpoints: {
    lg: "64rem",
    md: "1000px",
    // md: "58.125rem",
    sm: "1000px",
    // sm: "48rem",
    xs: "31.25rem",
  },
};
