import React from "react";
import {
  FooterDescription,
  // FooterLogo,
  FooterSections,
  FooterSection,
  FooterCompanyDetails,
  FooterSectionLink,
  FooterSectionsTitle,
  FooterLine,
  Copyright,
  FooterIcon
  // LegalLinks,
  // LegalLink,
} from "../styles/Footer.styled";
import { Section } from "../styles/Sections.styled";
import instagram from "../../assets/instagram_icon.png";
import email from "../../assets/email_icon.png";
import youtube from "../../assets/youtube_icon.png";
// import gymcatch from "../../assets/gymcatch.png";
import venmo from "../../assets/venmo_icon.png";

const Footer = () => {
  const year = new Date().getFullYear();
  // const youtubelink = "https://www.youtube.com/channel/UCiP7wurT3Db00cA72WHCSsA";
  const youtubelink = "https://www.youtube.com/@yoga.michael/playlists";
  const iglink = "https://www.instagram.com/michael.together.yoga/"
  const emaillink = "mailto:michael@athanas.org?subject=rosegarden"
  // const gclink = "https://gymcatch.com/app/provider/5622/events"
  const vmlink = "https://account.venmo.com/u/mathanas"

  return (
    <Section>
      <FooterSection>
        <FooterCompanyDetails>
          {/* <FooterLogo>Rose Garden Yoga</FooterLogo> */}
          <FooterDescription>

          </FooterDescription>
        </FooterCompanyDetails>
        <FooterSections>
          <FooterSectionLink>
            <FooterSectionsTitle><a href={emaillink} target="_blank" rel="noreferrer"><FooterIcon src={email} alt="email" /></a></FooterSectionsTitle>

          </FooterSectionLink>
          <FooterSectionLink>
            <FooterSectionsTitle><a href={youtubelink} target="_blank" rel="noreferrer"><FooterIcon src={youtube} alt="youtube" /></a></FooterSectionsTitle>
            </FooterSectionLink>
            <FooterSectionLink>            
            <FooterSectionsTitle><a href={iglink} target="_blank" rel="noreferrer"><FooterIcon src={instagram} alt="instagram" /></a></FooterSectionsTitle>

          </FooterSectionLink>
          <FooterSectionLink>            
            <FooterSectionsTitle><a href={vmlink} target="_blank" rel="noreferrer"><FooterIcon src={venmo} alt="venmo" /></a></FooterSectionsTitle>
          </FooterSectionLink>
          {/* <FooterSectionLink>            
            <FooterSectionsTitle><a href={gclink} target="_blank" rel="noreferrer"><FooterIcon src={gymcatch} alt="gymcatch" /></a></FooterSectionsTitle>

          </FooterSectionLink>           */}


        </FooterSections>
      </FooterSection>
      <FooterLine />
      <Copyright>© {year} All Rights Reserved, RoseGarden.Yoga</Copyright>


    </Section>
  );
};

export default Footer;
