import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 0.95rem 2.5rem;
  font-family: ${({ theme }) => theme.fonts.merriweather};
  background: ${(props) => (props.primary ? "#FF0034" : "#0736DB")};
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  letter-spacing: 2.5px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  
  text-decoration: none;
  

  &:hover {
    background: ${(props) => (props.primary ? "#CD0031" : "#022398")};
  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2rem;
    letter-spacing: 2px;

  }

  // @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  //   font-size: 10.8rem;
  //   padding: 0.5rem 1.25rem;
  // }
`;


export const StyledButtonSimplex = styled.button`
  padding: 0.95rem 2rem;
  font-family: ${({ theme }) => theme.fonts.merriweather};
  background: ${(props) => (props.primary ?  props.primary : "#FF0034")};
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  letter-spacing: 2.5px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  text-decoration: none;
  

  &:hover {
    background: ${(props) => (props.primary ? "#CD0031" : "#022398")};
  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.2rem;
    letter-spacing: 2px;

  }

  // @media all and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  //   font-size: 10.8rem;
  //   padding: 0.5rem 1.25rem;
  // }
`;