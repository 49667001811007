import React from "react";

import { StyledButtonSimplex} from "../styles/Button.styled";
import {
  BsFillPlayCircleFill as PlayIcon,
} from "react-icons/bs";



const RGButton = (props) => {
  var gclink="https://gymcatch.com/app/provider/5622/events";
  var label = "Class Schedule";
  if(props.link){
    gclink=props.link;
  }
  if(props.label){
    label=props.label;
  }
  var bgcolor = props.bgcolor ? props.bgcolor : "#FF0034";
  return (
            <div className="rgbutton">
                <a href={gclink} target="_blank" rel="noreferrer" style={{"textDecoration": "none"}} 
                className="acuity-embed-button acuitybutton" 
                >
                <StyledButtonSimplex primary={bgcolor} >
                    {label} <PlayIcon />
                </StyledButtonSimplex>
                </a>
          </div>

  );
};

export default RGButton;
