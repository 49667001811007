import styled from "styled-components";

export const MapTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1rem;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.25rem;
    text-align: ${(props) => (props.textDefault ? "left" : "center")};
  }

  @media all and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    font-size: 1.25rem;
  }
`;

export const MapSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MapContent = styled.div`
  
`